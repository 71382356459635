<template>
  <div id="assignment">
    <el-form
      ref="rules"
      :model="form"
      :rules="rules"
      label-width="120px"
      label-position="left
    "
    >
      <el-form-item label="作业名称" prop="name">
        <el-input
          class="textarea-title"
          type="textarea"
          placeholder="请输入作业名称"
          v-model="form.name"
          maxlength="500"
          :autosize="{ minRows: 1, maxRows: 50 }"
          show-word-limit
          style="width: 600px"
        ></el-input>
      </el-form-item>

      <el-form-item label="查看方式" :required="true">
        <el-radio-group v-model="form.privacy_type">
          <el-radio label="1">提交后可查看</el-radio>
          <el-radio label="2">未提交可查看</el-radio>
          <el-radio label="3">不可查看</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="作业内容" :required="true">
        <div slot="label">作业内容</div>
        <div class="contain">
          <div class="main">
            <div
              class="neirong"
              v-for="(item, index) in form.list"
              :key="item.nodeid"
              style="margin-bottom: 10px"
              @click="isclick(index)"
              :class="{ border: isselect === index }"
            >
              <!-- 插入文本 -->
              <div v-if="item.type == 1">
                <div :id="item.nodeid" class="editor my-editor"></div>
                <div class="fontlength">{{ item.realLength }}/500</div>
              </div>
              <!-- 插入图片 -->
              <div class="image" v-if="item.type == 2">
                <el-image :src="item.url"></el-image>
              </div>
              <!-- 插入视频 -->
              <div class="video" v-if="item.type == 4">
                <div class="img1">
                  <img :src="item.img" alt="" v-if="item.img" />
                  <img src="~@ass/img/1.4.1.2/icon_spbs.png" v-else alt="" />
                </div>
                <div class="play" @click.stop="preViewVideo(item.url)">
                  <img src="~@ass/img/1.4.1.2/icon_bfspan@2x.png" alt="" />
                </div>
              </div>
              <!-- 插入音频 -->
              <div class="audio" v-if="item.type == 3">
                <img src="~@ass/img/1.4.1.2/icon_ypwj@2x.png" alt="" />
                <div class="font1">
                  {{ item.name }}
                </div>
                <el-button type="text" @click.stop="openAudio(item)">
                  播放
                </el-button>
              </div>
              <div class="move" v-if="isselect === index">
                <div class="up_down" v-if="index == 0">
                  <!-- 上移 -->
                  <img src="~@ass/img/1.4.1.2/icon_syyc_hs@2x (1).png" alt="" />
                </div>
                <div
                  class="up_down"
                  @click="updown(index, index - 1, item)"
                  v-else
                >
                  <img src="~@ass/img/1.4.1.2/icon_syyc@2x (1).png" alt="" />
                </div>

                <div class="up_down" v-if="form.list.length - 1 == index">
                  <!-- 下移 -->
                  <img src="~@ass/img/1.4.1.2/icon_xyyc_hs@2x (1).png" alt="" />
                </div>

                <div
                  class="up_down"
                  @click="updown(index, index + 1, item)"
                  v-else
                >
                  <img src="~@ass/img/1.4.1.2/icon_xyyc@2x (1).png" alt="" />
                </div>
                <div class="up_down" @click="del(index)">
                  <!-- 删除 -->
                  <img src="~@ass/img/1.4.1.2/icon_ycmk备份 2@2x.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="btns">
            <div class="bt1" @click="addinput">
              <img src="~@ass/img/1.4.1.2/icon_crwb@2x.png" alt="" />
              <div class="name">插入文本</div>
            </div>
            <div
              class="bt1"
              @click="
                hadimage.length >= 10
                  ? $root.prompt('插入的内容数量最多10个！')
                  : picture()
              "
              :class="{ disabled: hadimage.length >= 10 }"
            >
              <img src="~@ass/img/1.4.1.2/icon_crtp@2x.png" alt="" />
              <div class="name">插入图片</div>
            </div>
            <div
              class="bt1"
              @click="
                hadvideo.length >= 10
                  ? $root.prompt('插入的内容数量最多10个！')
                  : shiping()
              "
              :class="{ disabled: hadvideo.length >= 10 }"
            >
              <img src="~@ass/img/1.4.1.2/icon_crsp@2x.png" alt="" />
              <div class="name">插入视频</div>
            </div>

            <div
              class="bt1"
              @click="
                hadaudio.length >= 10
                  ? $root.prompt('插入的内容数量最多10个！')
                  : yingpin()
              "
              :class="{ disabled: hadaudio.length >= 10 }"
            >
              <img src="~@ass/img/1.4.1.2/icon_cryp@2x.png" alt="" />
              <div class="name">插入音频</div>
            </div>
          </div>
          <div class="tips" v-if="subEmpty">作业内容不能为空</div>
        </div>
      </el-form-item>

      <el-form-item label="提交截止时间">
        <el-date-picker
          @change="timeChange"
          v-model="timeArr"
          type="datetimerange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
        <span class="time_select" v-if="tiemLenth > 0">
          累计时长： {{ tiemLenth | formatTimeLength(2) }}
        </span>
      </el-form-item>
    </el-form>

    <el-button
      type="primary"
      style="width: 126px"
      class="mt20"
      @click="onSubmit"
    >
      保存
    </el-button>

    <!-- 选择图片视频弹窗 
      @reset="resetnumber" -->
    <workaddpicture
      v-if="status3"
      :dialogstatus.sync="status3"
      @locationUpload="locationUpload"
      :pictureTitle="pictureTitle"
      :type="picktype"
      :size="picksize"
      :count="
        picktype == 1
          ? hadimage.length
          : picktype == 2
          ? hadaudio.length
          : hadvideo.length
      "
      @list="list"
      @audio="audio"
      @video="video"
      ref="workaddpicture"
      @group_id="group_id"
    ></workaddpicture>

    <!-- 视频播放 -->
    <previewVideo
      v-if="previewSrc"
      :src="previewSrc"
      @close="preViewVideo"
    ></previewVideo>

    <!--本地视频上传 -->
    <uploadfile
      v-if="uploadStart2"
      @uploadConfirmClose="uploadConfirmClose"
      @completelist="completelist"
      @close="uploadConfirmClose"
      :typeselection="2"
      :title="'上传文件'"
      :list="group_id1"
    ></uploadfile>

    <!-- 本地图片/音频上传 -->
    <upload
      v-if="uploadStart"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      :title="title"
      :uploadType="uploadType"
      :list="group_id1"
    ></upload>

    <loading v-show="isLoading" leftPos="0"></loading>
  </div>
</template>
<script>
import E from 'wangeditor'
import workaddpicture from './workaddpicture'
// import API from '@/request/api'
import previewVideo from '@cm/coursePop/previewVideo'
import upload from '@cm/base/UploadtextFile'
import uploadfile from '@cm/base/UploadFile'
import loading from '@/components/LoadGif'
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() - 8.64e7 //设置选择今天以及今天之后的日期
        },
      },

      tiemLenth: 0,

      // 提交时间
      timeArr: [],

      subEmpty: false, //保存时,判断,主题内容是否为空

      isselect: '', //被选中的索引

      // 编辑器课程详情
      content: '',

      group_id1: '', //选中分组的id

      isLoading: false, //加载状态

      audioval: {}, //选择音频的内容

      dataId: '', //id

      // 老师列表
      options: [],

      uploadStart2: false, //视频本地上传弹窗

      uploadStart: false, //本地上传弹窗控制

      status3: false, //添加图片

      //控制选项的个数

      title: '',

      uploadType: '',

      pictureTitle: '',

      picktype: '', //1是图片2是音频 3视频

      picture_audio: [], //音频的数据

      rules: {
        name: [{ required: true, message: '请输入作业名称', trigger: 'blur' }],

        // teacher_id: [
        //   {
        //     required: true,
        //     message: '请输入老师名称或选择上课老师',
        //     trigger: 'change',
        //   },
        // ],
      },

      previewSrc: '', //当前播放视频的格式

      charu_index: 0, //0图片 1音频

      //主题整体数据
      form: {
        privacy_type: '1',
        name: '',
        homework_id: '',
        end_submit_time: '',
        start_submit_time: '',
        // teacher_id: '',
        list: [],
      },

      editors: [],
    }
  },

  components: {
    // helpIcon,
    workaddpicture,
    upload,
    loading,
    uploadfile,
    previewVideo,
  },

  created() {
    // this.getJigouTeacher()
    this.dataId = this.$route.params.id2
    if (this.$route.params.type !== 'new') {
      this.getDate()
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑作业'
    }
  },

  beforeDestroy() {
    this.editors.forEach(
      item => item && typeof item.destroy === 'function' && item.destroy()
    )
  },

  computed: {
    isnew() {
      if (this.$route.params.type == 'new') {
        return false
      } else {
        return true
      }
    },

    hadvideo() {
      return this.form.list.filter(item => item.type == 4)
    },

    hadaudio() {
      return this.form.list.filter(item => item.type == 3)
    },

    hadimage() {
      return this.form.list.filter(item => item.type == 2)
    },
  },

  watch: {
    isselect() {
      this.$notify.closeAll()
    },
  },

  methods: {
    timeChange(val) {
      if (val) {
        this.timeArr = val
        this.tiemLenth = (val[1] - val[0]) / 1000
        this.form = _.assign({}, this.form, {
          start_submit_time: val[0] / 1000,
          end_submit_time: val[1] / 1000,
        })
      } else {
        this.timeArr = []
        this.tiemLenth = 0
        this.form = _.assign({}, this.form, {
          start_submit_time: '',
          end_submit_time: '',
        })
      }
    },

    //主题内容的移动
    updown(index, newIndex) {
      const old = this.form.list[newIndex]
      this.form.list.splice(newIndex, 1, this.form.list[index])
      this.form.list.splice(index, 1, old)
    },

    //删除主题内容的数据
    del(val) {
      this.form.list.splice(val, 1)
    },

    // 预览视频
    preViewVideo(src) {
      this.previewSrc = typeof src === 'string' ? src : ''
    },

    completelist() {},

    // 被选中时的状态
    isclick(val) {
      this.isselect = val
    },

    // 初始化编辑器
    initWangeditor(val) {
      const editor = new E(`#${val}`)
      // 隐藏“网络图片”tab
      editor.config.onchange = html => {
        const tem = editor.txt.text()
        const length = tem.length
        this.form.list.forEach(item => {
          const str = tem.slice(0, 500)
          if (item.nodeid == val) {
            if (length > 500) {
              editor.txt.text(str)
            }
            item.content_text = html
            item.realLength = tem.length
          }
        })
      }
      editor.config.customAlert = s => {
        this.$root.prompt(s)
      }

      // 自定义菜单配置
      editor.config.menus = [
        // 'head', // 标题
        'bold', // 粗体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'justify', // 对齐方式
        'undo', // 撤销
      ]
      editor.create()
      this.form.list.forEach(item => {
        if (item.nodeid == val) {
          editor.txt.html(item.content_text)
          item.realLength = item.content_text
            .replace(/<\/?.+?>/g, '')
            .replace(/\//g, '').length
        }
      })

      return editor
    },

    //获取插入图片,视频,音频中选择的分组id
    group_id(val) {
      this.group_id1 = val
    },

    //获取编辑数据
    getDate() {
      this.$http({
        url: `/Homework/editTheme?homework_theme_id=${this.$route.params.type}`,
        // data: {},
        callback: ({ data }) => {
          const list = data.list.map(item => {
            return _.assign({}, item, { realLength: 0 })
          })
          this.form = _.assign({}, data, {
            list: list,
          })

          let istext = data.list.filter(item => item.type == 1)
          this.$nextTick(() => {
            istext.forEach(item => {
              this.editors.push(this.initWangeditor(item.nodeid))
            })
          })
          if (data.start_submit_time && data.end_submit_time) {
            this.timeChange([
              data.start_submit_time * 1000,
              data.end_submit_time * 1000,
            ])
            this.timeArr = [
              data.start_submit_time * 1000,
              data.end_submit_time * 1000,
            ]
          }

          // console.log(data.list.filter((item) => item.type == 1))
        },
      })
    },

    //音频预览
    openAudio(audioval) {
      let name = audioval.name
      let url = audioval.url
      this.$notify.closeAll()
      this.$notify({
        customClass: 'popAudio1404',
        offset: 100,
        duration: 0,
        title: '正在播放:' + name,
        dangerouslyUseHTMLString: true,
        message: `<audio class="audio" src=${encodeURI(
          url
        )} autoplay controls="controls">`,
        onClose: this.closefun,
      })
      setTimeout(() => {
        document.querySelector('.el-notification__title').title = name
      }, 500)
    },

    //删除单个选项
    selectremove(val) {
      if (this.form.option.length == 1) {
        this.$root.prompt({
          msg: '题目必须保证有一个选项',
        })
        return
      }
      this.form.option.splice(val, 1)
    },

    // 获取机构老师
    // getJigouTeacher() {
    //   const self = this
    //   self.$http({
    //     name: API.getJigouTeacher_Api.name,
    //     url: API.getJigouTeacher_Api.url,
    //     callback({ data: { teacherInfo } }) {
    //       self.options = teacherInfo
    //     },
    //     error(error) {
    //       if (error.code == 204) {
    //         self.options = []
    //       }
    //     },
    //   })
    // },

    //添加文本
    addinput() {
      const option = {
        id: Math.random(),
        type: 1,
        content_text: '',
        source_id: '',
        nodeid: 'editor' + parseInt(Math.random() * 1000000),
        realLength: 0,
      }
      this.form.list.push(option)
      this.$nextTick(() => {
        this.editors.push(this.initWangeditor(option.nodeid))
      })
    },

    // 插入图片的数据
    list(val) {
      val.forEach(item => {
        this.form.list.push({
          id: Math.random(),
          type: 2,
          url: item.url,
          source_id: item.images_id,
          nodeid: 'editor' + parseInt(Math.random() * 1000000),
        })
      })
    },

    //插入音频得数据
    audio(val) {
      val.forEach(item => {
        this.form.list.push({
          id: Math.random(),
          type: 3,
          url: item.url,
          source_id: item.audio_id,
          name: item.name,
          nodeid: 'editor' + parseInt(Math.random() * 1000000),
        })
      })
    },

    //插入视频的数据
    video(val1) {
      val1.forEach(val => {
        this.form.list.push({
          id: Math.random(),
          type: 4,
          url: val.video_url,
          source_id: val.video_id,
          img: val.video_img,
          nodeid: 'editor' + parseInt(Math.random() * 1000000),
        })
      })
    },

    //插入图片弹窗
    picture() {
      this.title = '上传图片(多选)'
      this.uploadType = 2
      this.pictureTitle = '插入图片(多选)'
      this.picktype = 1
      this.picksize = 20
      this.$nextTick(() => {
        this.status3 = true
      })
    },

    //插入音频弹窗
    yingpin() {
      this.title = '上传音频(多选)'
      this.uploadType = 3
      this.pictureTitle = '插入音频(多选)'
      this.picktype = 2
      this.picksize = 100
      this.$nextTick(() => {
        this.status3 = true
      })
    },

    //插入视频弹窗
    shiping() {
      this.title = '上传视频(多选)'
      this.uploadType = 4
      this.pictureTitle = '插入视频(多选)'
      this.picktype = 3
      this.picksize = 100
      this.$nextTick(() => {
        this.status3 = true
      })
    },

    //保存
    onSubmit() {
      // 判断列表中是否有一个有数据
      // 使用every函数，如果有一个符合条件（就是列表中存在一个有数据的值）就允许保存
      // 否则为false进行提示 内容主题为空
      const noempty = this.form.list.some(item => {
        if (item.type == 1) {
          return (
            item.content_text && /[^<|p|>|br|\\/|\s]/.test(item.content_text)
          )
        } else {
          return item.url
        }
      })

      //并且类型是1
      if (!noempty) {
        //不能保存时，停止执行以下代码，防止只有文本框，且都为空时，文本框被清理掉
        this.subEmpty = true
        return
      } else {
        this.subEmpty = false
      }

      //如果有多个文本框，有任意一个文本框有内容，那么清理掉其他的文本框后保存
      this.form = _.assign({}, this.form, {
        name: this.form.name.trim(),
        homework_id: this.dataId,
        list: this.form.list.filter(
          item => !(item.type == 1 && !item.content_text)
        ),
      })

      setTimeout(() => {
        this.$refs.rules.validate(valid => {
          if (valid && !this.subEmpty) {
            this.isLoading = true
            if (this.$route.params.type == 'new') {
              this.$http({
                url: 'Homework/createTheme',
                data: this.form,
                callback: () => {
                  this.$router.go(-1)
                  this.isLoading = false
                  this.$root.prompt({
                    msg: '操作成功',
                    type: 'success',
                  })
                },
                error: () => {
                  this.isLoading = false
                  this.$root.prompt('操作失败')
                },
              })
            } else {
              this.form.homework_id = this.$route.params.type
              this.$http({
                url: '/Homework/editTheme',
                data: this.form,
                callback: () => {
                  this.$router.go(-1)

                  this.isLoading = false
                  this.$root.prompt({
                    msg: '操作成功',
                    type: 'success',
                  })
                },
                error: () => {
                  this.isLoading = false
                  this.$root.prompt('操作失败')
                },
              })
            }
          }
        })
      }, 0)
    },

    //添加选项
    add() {
      if (this.form.option.length == 8) {
        this.$root.prompt({
          msg: '最多支持8个选项',
        })
        return
      }
      this.form.option.push({ id: this.form.option.length, value: '' })
    },

    // 2本地上传图片 3视频
    locationUpload() {
      if (this.picktype == 3) {
        this.uploadStart2 = true
      } else {
        this.uploadStart = true
      }
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.$refs.workaddpicture.renew()
      this.uploadStart = false
      this.uploadStart2 = false
    },
  },
}
</script>

<style lang="less">
.time_select {
  margin-left: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.el-textarea .el-input__count {
  bottom: 23px;
  right: 7px;
  height: 9px;
}
.el-notification__title {
  width: 270px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style lang="scss" scoped>
::v-deep .textarea-title textarea {
  min-height: 40px !important;
}
#assignment {
  background-color: #fff;
  padding: 20px;
  ::v-deep .el-form-item__label {
    display: flex;
    align-items: center;
  }

  .border {
    border: 1px solid #0aa29b !important;
  }
  .contain {
    border: 1px dotted #979797;
    width: 600px;
    display: flex;
    flex-flow: column;
    position: relative;
    .tips {
      position: absolute;
      bottom: -32px;
      left: 0px;
      color: #f56c6c;
      font-size: 12px;
    }
    .main {
      padding: 20px 64px 20px 14px;
      box-sizing: border-box;
      ::v-deep .w-e-toolbar {
        border-radius: 2px 2px 0 0 !important;
        background-color: #fff !important;
        border-bottom: 0 !important;
        line-height: 15px;
        border: 0 !important ;
      }
      ::v-deep .w-e-toolbar {
        z-index: 10 !important;
      }
      ::v-deep .w-e-text {
        overflow: auto;
        border-radius: 2px;
        padding-bottom: 15px;
      }
      ::v-deep .w-e-text-container {
        height: auto !important;
        border: 0 !important ;
      }
      .neirong {
        position: relative;
        border: 1px solid #e8e8e8;
        border-radius: 2px;
        .fontlength {
          position: absolute;
          right: 10px;
          bottom: -10px;
          z-index: 99;
        }
        .move {
          background-color: #fff;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.18);
          border-radius: 4px;
          width: 40px;
          top: 0;
          right: -50px;
          position: absolute;
          .up_down {
            cursor: pointer;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            img {
              width: 16px;
              height: auto;
            }
          }
        }
        .video {
          height: 294px;
          position: relative;
          .img1 {
            width: 100%;
            height: 100%;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .play {
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
              width: 56px;
              height: 56px;
            }
          }
          .video2 {
            width: 100%;
            height: 100%;
          }
        }
        .audio {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 14px 16px;
          margin-top: 0 !important;
          img {
            width: 16px;
            height: 20px;
            margin-right: 10px;
          }
          .font1 {
            width: calc(100% - 45px);
            font-size: 13px;
            color: #333333;
            line-height: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .btns {
      margin-top: 10px;
      padding: 10px 128px;
      width: 100%;
      background-color: #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-sizing: border-box;
      .bt1 {
        width: 78px;
        cursor: pointer;
        &.disabled {
          filter: grayscale(1);
        }
        img {
          margin: 0 auto;
          width: 24px;
          height: auto;
        }
        .name {
          text-align: center;
          margin-top: 8px;
          font-size: 12px;
          color: #1b9d97;
          line-height: 12px;
        }
      }
    }
  }
  .photo_vedieo {
    width: 600px;
    border: 1px solid #e8e8e8;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top: 0;
    padding: 20px 10px;
    box-sizing: border-box;

    .span_1 {
      color: #1b9d97;
      margin: 0 20px;
    }
    .span_2 {
      color: #1b9d97;
      margin: 0 10px;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .zi1 {
        font-size: 12px;
        color: #666666;
        line-height: 12px;
      }
    }
    .bottom {
      .picture_audio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .left {
          font-size: 12px;
          color: #666666;
          line-height: 12px;
          width: calc(100% - 91px);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .right {
          font-size: 12px;
          color: #1b9d97;
          line-height: 12px;
        }

        .bigpictrue {
          position: absolute;
          cursor: pointer;
          width: 40px;
          height: 30px;
          top: 7px;
          right: 50px;
          ::v-deep .el-image__inner {
            opacity: 0;
          }
        }
      }
    }
  }

  ::v-deep .el-textarea__inner {
    padding-top: 9px;
  }

  .zi2 {
    margin-left: 20px;
    font-size: 12px;
    color: #666666;
    line-height: 12px;
  }
}
</style>
